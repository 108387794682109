/*eslint no-unused-vars: "off"*/

import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@material-ui/core';

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Preloader from './../../../s4n/Preloader'
import classNames from 'classnames';
// import Yup from './../../../s4n/Yup';
import FormHelper from './helper'
import { DisplayFormikState } from "./../../formikHelper"
import Harmonogram from './../../../../model/Harmonogram'



const oFormHelper = new FormHelper();


const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});

const ObjectStateless = (props) => {
  const { classes, intl } = props;
  const t = intl.formatMessage;

  // Declare a new state variable, which we'll call "formStatus"
  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: true,
    error: false,
    formSchema: null,
    initialValues: null,
    validationSchema: null,
  });

  // const node = props.harmonogramNode;
  // let windowLocationHref = `${process.env.SOFT4NET_STATIC_BASE_URL}${node.path.alias}`;
  // if (`undefined` !== typeof window) {
  //   windowLocationHref = window.location.href;
  // }

  // const oHarmonogramModel = new Harmonogram(node, intl);

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  useEffect(() => {
    componentDidMount();
  }, []);

  const componentDidMount = async () => {
    // Set data for FormHelper START
    // const oFormHelper = new FormHelper(intl);
    oFormHelper.setIntl(intl);

    oFormHelper.t = t;
    oFormHelper.setFormStatus = setFormStatus;
    // Set data for FormHelper STOP

    

    // Form schema does not contain markup elements!
    const formSchema = await oFormHelper.getFormSchema(props.webformId);
// console.log(`formSchema: `, formSchema)

    oFormHelper.setFormSchema(formSchema);

    const initialValues = {
      webform_id: props.webformId,
      ...oFormHelper.getInitialValues(formSchema),
    };
// console.log(`initialValues: `, initialValues)
    const validationSchema = oFormHelper.getValidationSchema(formSchema);
// console.log(`validationSchema: `, validationSchema)

    setState(prevState => {
        return {
            ...prevState, 
            loading: false,
            formSchema,
            initialValues,
            validationSchema,
        };
    });
  }


  return (
    <React.Fragment>
      {state.loading ? // or state.loading this is a reference
        <Preloader /> 
        : 
        <Formik
          // enableReinitialize={true} // Thanks to this Formik will update when context (`contextProductIndex`) changes!!!, @see: https://github.com/jaredpalmer/formik/issues/811
          // initialValues={{
          //   webform_id: props.webformId,
          //   ...oFormHelper.getInitialValues(state.formSchema),
          // }}
          initialValues={state.initialValues}
          onSubmit={oFormHelper.onSubmit}
          // onSubmit={() => { alert(`Form submited!`) }}
          // validationSchema={oFormHelper.getValidationSchema(state.formSchema)}
          validationSchema={state.validationSchema}
        >
          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,

              initialValues,
            } = props;

// console.log(status)
// console.log(`state.formSchema.schema: `, state.formSchema.schema) 

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            return (
              <Form 
                  onSubmit={handleSubmit} 
                  className={initialValues.webform_id}
              >

                <h1>{state?.formSchema?.schema.title}</h1>

                {state?.formSchema?.ui[`ui:order`].map((fieldId, index) => {
// console.log(fieldId)

                  // if (`radio` !== state.formSchema.ui?.[fieldId]?.[`ui:widget`]) { // skip non option value
                  //   return null;
                  // }

                  const field = state.formSchema.schema.properties?.[fieldId] || null;
// console.log(field)

                  if (null === field || field.is_composite_element) {
                    return null;
                  }
                  
                  const uiWidget = state.formSchema.ui[fieldId]?.[`ui:widget`] || null;

                  let TEXTAREA = {}
                  if (`textarea` === uiWidget) {
                    TEXTAREA = {
                      multiline: true,
                      rows: "5",
                      rowsMax: "5",
                    }
                  }

                  return (
                    <React.Fragment>

                      <div className="row">
                        <div className="col">
                          <TextField
                            id={`${initialValues.webform_id}_${fieldId}`}
                            label={oFormHelper.getFieldTitle(fieldId)}
                            name={fieldId}
                            error={errors[fieldId] && touched[fieldId]}
                            
                            helperText={(errors[fieldId] && touched[fieldId]) && errors[fieldId]} // ???
                            // helperText={errors[fieldId] && touched[fieldId]} // ???

                            className={classes.textField}
                            value={values[fieldId]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            margin="normal"

                            {...TEXTAREA}
                          />
                        </div>
                      </div>

                      {/* {`a_weryfikacji_` === fieldId && 
                        <strong className="d-block pt-3 pb-2">{ t({ id: `soft4net_webform___heuresis_outsourcing_diagnoza_fi___section_1` }) }</strong>}
                      {`a_szybkie_odchodzenie_nowych_pracownikow_` === fieldId && 
                        <strong className="d-block pt-3 pb-2">{ t({ id: `soft4net_webform___heuresis_outsourcing_diagnoza_fi___section_2` }) }</strong>}

                      <FormControl 
                        component="fieldset"
                        // className={classes.formControl}
                      >
                        <FormLabel 
                          component="legend"
                          error={errors[fieldId] && touched[fieldId]}
                          helperText={(errors[fieldId] && touched[fieldId]) && errors[fieldId]}
                        >{field.title}</FormLabel>
                        <RadioGroup 
                          aria-label={fieldId} 
                          name={fieldId} 
                          // value={value} 
                          // value={values[fieldId]} 
                          // onChange={(event) => {
                          //   handleChange(event)
                          // }} 
                          onChange={handleChange} 
                          row
                        >

                            {Array.isArray(field.anyOf) && field.anyOf.map(option => {
                              const radio = option.enum[0]; // const radio = option.title;
                              // console.log(option)

                              return <FormControlLabel
                                value={radio}
                                control={
                                  <Radio 
                                    color="primary"
                                    checked={radio === values[fieldId]} // this controls material ui if checked and reset, this reflects current state: if checked, what value has!!!
                                  />
                                }
                                label={option.title}
                                // labelPlacement="end"
                              />

                            })}

                        </RadioGroup>
                        <FormHelperText>labelPlacement start</FormHelperText>
                      </FormControl> */}

                    </React.Fragment>
                  )
                })}



                {/* Form status messages */}
                <div className="row form-status">
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <DialogActions>
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >{ t({ id: "soft4net_form_action_reset" }) }</Button>

                      {isSubmitting ? 
                        <CircularProgress /> : 
                        <Button 
                          type="submit" 
                          variant="contained" 
                          color="primary"
                          className={formStatus && formStatus.success ? 'green' : null}
                          disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                      }

                    </DialogActions>
                  </div>
                </div>



                {/* <DisplayFormikState {...props} /> */}
                {/* <DisplayFormikState {...state} /> */}



              </Form>
            );
          }}
        </Formik>
      }
      </React.Fragment>
    )
}

export default withStyles(styles)(injectIntl(ObjectStateless))