// import React from 'react';
// import React, { useState, useEffect } from 'react';
// import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import axios from 'axios';
// import ReactDOMServer from 'react-dom/server';
import Yup from './../../../s4n/Yup';


class FormHelper {
    // formSchema = null;

    // Injected attributes
    // setFormStatus: null,
    // state: null,
    // setState: null,

    // formEndpoint = process.env.SOFT4NET_SOURCE_BASE_URL + '/webform_rest/submit';
    // formSchemaEndpoint: `/webform_jsonschema/${webform_id}?_format=json`,
    // setFormStatus: () => {

    constructor() {
// console.log(`constructor`)

        // this.node = node;
        this.intl = null;
        this.webform_id = null;
        this.formSchema = null;
        this.initialValues = null;
        this.validationSchema = null;
    }

    getFormSchema = async (webform_id) => {
        // this.webform_id = webform_id; // not working!

        const formSchemaEndpoint = `/webform_jsonschema/${webform_id}?_format=json`;
   
        const response = await axios.get(
            `${process.env.SOFT4NET_SOURCE_BASE_URL}/${this.intl.locale}${formSchemaEndpoint}`, {
                auth: {
                    username: process.env.BASIC_AUTH_USERNAME,
                    password: process.env.BASIC_AUTH_PASSWORD,
                }
                // headers: {
                //   'Access-Control-Allow-Origin': '*',
                // },
                // crossdomain: true
        });

        const formSchema = response.data;

        this.formSchema = formSchema;
// console.log(`this.formSchema: `, this.formSchema);

        return formSchema;
    };

    // For now this is just only for heuresis_outsourcing_diagnoza_fi.js!!!
    getInitialValues = (
        // _initialValues = {}
        formSchema
    ) => {
        let _initialValues = {}

        // if (null !== this.initialValues) {
        //     return this.initialValues;
        // }

        this.formSchema = formSchema;
// console.log(`this.formSchema: `, formSchema);

        let initialValues = {..._initialValues}

        // initialValues[`webform_id`] = this.webform_id;

        this?.formSchema?.schema && Object.keys(this.formSchema.schema.properties).forEach(fieldId => {
// console.log(`fieldId: `, fieldId)
        //   if (`radio` !== this.formSchema.ui?.[fieldId]?.[`ui:widget`]) { // skip non option value
        //     return null;
        //   }
    
          // const field = state.formSchema.schema.properties[fieldId];
          initialValues[fieldId] = ``;
        })

        // this.initialValues = initialValues;
// console.log(`initialValues: `, initialValues);
        return initialValues;
    };
    
    // For now this is just only for heuresis_outsourcing_diagnoza_fi.js!!!
    getValidationSchema = (formSchema) => {
        // if (null !== this.validationSchema) {
        //     return this.validationSchema;
        // }

        this.formSchema = formSchema;
// console.log(`this.formSchema: `, this.formSchema);

        let validationSchema = {};

        this.formSchema.schema.required.forEach((fieldId, index) => {
// console.log(`fieldId: `, fieldId)
            const {type, format} = this.formSchema.schema.properties[fieldId];
            const validationMessage = this.formSchema.ui?.[fieldId]?.[`webform:validationErrorMessages`].required || undefined;
// console.log(`validationMessage: `, validationMessage)

            if (`email` === format) {
                validationSchema[fieldId] = Yup.string().email().required(validationMessage);
            } else if (`radio` === this.formSchema.ui?.[fieldId]?.[`ui:widget`]) { // option value
                validationSchema[fieldId] = Yup.string().required(validationMessage);
            } else if (`string` === type) {
                validationSchema[fieldId] = Yup.string().required(validationMessage);
            }
        })

        // this.validationSchema = validationSchema;

        return Yup.object().shape(validationSchema);
        return validationSchema;
    };

    getField = (fieldId) => {
// console.log(`this.formSchema: `, this.formSchema);
        return this?.formSchema?.schema?.properties[fieldId] || null;
    };
    
    getFieldTitle = (fieldId) => {
        return this.getField(fieldId)?.title || ``;
    };

    getFieldItems = (fieldId) => {
        let items = [];
        const field = this.getField(fieldId);
// console.log(field);

        if (null === field) {
            return items;
        }

        if (typeof field.items === `undefined`) {
            items = field.anyOf ? field.anyOf : items;
        } else {
            items = field.items.anyOf ? field.items.anyOf : items;
        }

        return items;
    };

    getFieldOptionEnum = (fieldId, index) => {
        return this.getField(fieldId) ? this.getField(fieldId).anyOf[index].enum[0] : ``;
    };

    getFieldOptionTitle = (fieldId, index) => {
        return this.getField(fieldId) ? this.getField(fieldId).anyOf[index].title : ``;
    };

    onSubmit = async (values, actions) => {
        //setSubmitting(true);
        actions.setStatus(undefined);
    
        // @see: https://www.drupal.org/project/webform_rest
        // set "webform_id": "contact_s4n",
        // values.webform_id = webform_id;

        // URL from which form was sent START
        let windowLocationHref = ``;
        if (typeof window !== `undefined`) {
            windowLocationHref = window.location.href;
        }
        values.url = {
            title: `${windowLocationHref}`,
            url: `${windowLocationHref}`,
            target: "_blank",
        };
        // URL from which form was sent STOP

        
        axios.defaults.baseURL = process.env.SOFT4NET_SOURCE_BASE_URL;    
        
        // const tokenResponce = await axios.get('/rest/session/token');
        // const csrfToken = tokenResponce.data;
        const csrfToken = this.formSchema.csrfToken;
    
        const response = await axios.post('/webform_rest/submit',
            values, 
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
                auth: {
                    username: process.env.BASIC_AUTH_USERNAME,
                    password: process.env.BASIC_AUTH_PASSWORD,
                }
            },
        );
    
        // console.log(response)
        // {
        //   "sid": "1"
        // }
    
        if (response.status === 200 && response.data.sid) {
            this.setFormStatus({
                success: true,
                class: 'success',
                // message: ReactDOMServer.renderToStaticMarkup(<FormattedMessage id="soft4net_form_status_success" />),
                message: this.t({ id: "soft4net_form_status_success" }),
            });
    
            // @todo: this is not working check why!?
            actions.setStatus({
                success: true
            });
          
            actions.resetForm();
        } else {
            let message = `${this.t({ id: "soft4net_form_status_danger" })}<br />\n`;
            if (response.data.error) {
                for (const field in response.data.error) {
                    // message += `${field}: ${response.data.error[field]}<br />\n`;
                    message += `- ${response.data.error[field]}<br />\n`;
                }
            }
    
            this.setFormStatus({
                success: true,
                class: 'danger',
                message: message
            });
        }
    
        actions.setSubmitting(false);
    }

    setIntl = (intl) => {
        this.intl = intl;
    }

    setFormSchema = (formSchema) => {
        this.formSchema = formSchema;
    }
}

export default FormHelper;